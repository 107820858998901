import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import generatePDF, { Margin, Resolution, usePDF } from 'react-to-pdf'
import { toast, ToastContainer } from 'react-toastify'
import SelectVendor from '../../Components/Contracts/SelectVendor'
import AddItemToInvoicePopUp from '../../Components/Invoices/AddItemToInvoicePopUp'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './EachHiringInvoice.module.css'

import { FaToggleOn, FaToggleOff, FaBookmark } from "react-icons/fa";
import { MdOutlineDownloading, MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md'
import { addPeriodToDate, numberToWords } from '../../Helper/helpers'
import LogoInvoice from '../../Components/Invoices/LogoInvoice'
import { IoMdCloudDownload } from 'react-icons/io'
import PaidStamp from '../../Components/Invoices/PaidStamp'
import UnpaidStamp from '../../Components/Invoices/UnpaidStamp'


const EachHiringInvoice = () => {
    const authCtx = useContext(AuthContext)
    const { invoiceIdentifier } = useParams()
    const [isAddingItemOpen, setIsAddingItemOpen] = useState(false)
    const toggleAddingItem = () => setIsAddingItemOpen(false)
    const [invoiceDetails, setInvoiceDetails] = useState(null)
    const [allItems, setAllItems] = useState([])
    const [isGstApplicable, setIsGstApplicable] = useState(true)
    const [gstType, setGstType] = useState('IGST') // IGST, LOCAL
    const [taxInWords, setTaxInWords] = useState('')
    const [amountInWords, setAmountInWords] = useState('')
    const [grandTotal, setGrandTotal] = useState(0)
    const [grandTotalGst, setGrandTotalGst] = useState(0)
    const [clientGst, setClientGst] = useState('')
    const [legalName, setLegalName] = useState('')
    const [tradeName, setTradeName] = useState('')
    const [businessAddress, setBusinessAddress] = useState('')

    const [allGstRecords, setAllGstRecords] = useState([])
    const [selectedGst, setSelectedGst] = useState(null)
    const [addingGst, setAddingGst] = useState(false)
    const [hasUpdated, setHasUpdated] = useState(false)
    const [isUpdatingInvoice, setIsUpdatingInvoice] = useState(false)
    const [dueDate, setDueDate] = useState()
    const [formattedDate, setFormattedDate] = useState(""); // Stores the formatted date (DD-MM-YYYY)
    const [replacementPeriod, setReplacementPeriod] = useState('21 days')
    const [isSelectVendorOpen, setIsSelectVendorOpen] = useState(false)

    const [tnc, setTnc] = useState([])

    const toggleSelectVendor = () => {
        setIsSelectVendorOpen(false)
    }
    const selectVendorHandler = (k) => {
        setClientDetailsForInvoice(k)
    }

    const setClientDetailsForInvoice = async (k) => {
        const setClientDetailsForInvoiceResponse = await fetch(BASE_URL_API + "/setClientDetailsForInvoice",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    invoiceIdentifier: invoiceIdentifier,
                    vendorIdentifier: k?.vendorIdentifier,
                    storeIdentifier: k?.store_id,
                    customerName: k?.company_name,
                    customerAddress: k?.address,
                    customerPhone: k?.phoneNumber
                })
            });

        if (!setClientDetailsForInvoiceResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const setClientDetailsForInvoiceRespo = await setClientDetailsForInvoiceResponse.json()
            if (setClientDetailsForInvoiceRespo?.status === "success") {
                toast(`Client details updated successfully!`)
                getInvoiceDetails()
            } else {
                if (setClientDetailsForInvoiceRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    console.log(setClientDetailsForInvoiceRespo)
                }
            }
        }
    }

    function formatDateForInput(dateString) {
        // Replace the space with 'T' to make it an ISO-like format
        const formattedDate = dateString.replace(' ', 'T');
        
        // Create a new Date object from the formatted string
        const dateObj = new Date(formattedDate);
        
        // Add one day
        dateObj.setDate(dateObj.getDate() + 1);
        
        // Extract the date part in 'YYYY-MM-DD' format
        return dateObj.toISOString().slice(0, 10);
    }

    const getInvoiceDetails = async () => {
        const getInvoiceDetailsResponse = await fetch(BASE_URL_API + "/getInvoiceDetails",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    invoiceIdentifier: invoiceIdentifier
                })
            });

        if (!getInvoiceDetailsResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getInvoiceDetailsRespo = await getInvoiceDetailsResponse.json()
            if (getInvoiceDetailsRespo.status === "success") {
                setInvoiceDetails(getInvoiceDetailsRespo.response)
                setDueDate(formatDateForInput(getInvoiceDetailsRespo?.response?.dueDate))
            } else {
                if (getInvoiceDetailsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setInvoiceDetails(null)
                }
            }
            console.log(getInvoiceDetailsRespo)
        }
    }

    const getItemsForInvoice = async () => {
        const getItemsForInvoiceResponse = await fetch(BASE_URL_API + "/getItemsForInvoice",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    invoiceIdentifier: invoiceIdentifier
                })
            });

        if (!getItemsForInvoiceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getItemsForInvoiceRespo = await getItemsForInvoiceResponse.json()
            if (getItemsForInvoiceRespo?.status === "success") {
                setAllItems(getItemsForInvoiceRespo?.response)
            } else {
                if (getItemsForInvoiceRespo?.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllItems([])
                }
            }
            console.log(getItemsForInvoiceRespo)
        }
    }

    const removeItemFromInvoice = async (k) => {
        const removeItemFromInvoiceResponse = await fetch(BASE_URL_API + "/removeItemFromInvoice",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    invoiceItemIdentifier: k
                })
            });

        if (!removeItemFromInvoiceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const removeItemFromInvoiceRespo = await removeItemFromInvoiceResponse.json()
            if (removeItemFromInvoiceRespo?.status === "success") {
                toast(`Successfully removed!`)
                getItemsForInvoice()
            } else {
                if (removeItemFromInvoiceRespo?.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(removeItemFromInvoiceRespo)
        }
    }

    useEffect(() => {
        getInvoiceDetails()
        getItemsForInvoice()
    }, [invoiceIdentifier])

    const onSuccessItemAdding = () => {
        toast(`Item added successfully!`)
        getItemsForInvoice()
    }


    const options = {
        resolution: Resolution.HIGH,
        page: {
            margin: Margin.SMALL,
            // Set the format and orientation if needed
            // format: 'letter',
            // orientation: 'landscape',
        }
    };

    const getTargetElement = () => document.getElementById('content-id');

    const downloadPdfHandler = () => {
        const contentElement = document.getElementById("content-id");

        // Temporarily expand the element to its full height
        const originalWidth = contentElement.style.width;
        const originalHeight = contentElement.style.height;

        // Set width and height to auto to capture all content
        contentElement.style.width = '8.27in'; // A4 width in inches
        contentElement.style.height = 'auto';

        // Generate the PDF with full content
        generatePDF(getTargetElement, options).then(() => {
            // Restore original dimensions
            contentElement.style.width = originalWidth;
            contentElement.style.height = originalHeight;
        }).catch(error => {
            console.error('Error generating PDF:', error);
        });
    };


    const sumQuantities = (items) => {
        // Return 0 if items is null, undefined, or not an array
        if (!Array.isArray(items)) return 0;

        return items.reduce((sum, item) => {
            const quantity = parseInt(item?.quantity, 10);
            // Add to sum only if quantity is a valid number
            return sum + (isNaN(quantity) ? 0 : quantity);
        }, 0);
    };


    const sumCost = (items) => {
        // Return 0 if items is null, undefined, or not an array
        if (!Array.isArray(items)) return '0.00';

        const totalCost = items.reduce((sum, item) => {
            const unitPrice = parseFloat(item?.unitPrice);
            // Add to sum only if unitPrice is a valid number
            return sum + (isNaN(unitPrice) ? 0 : unitPrice);
        }, 0);

        // Return total cost with 2 decimal places as a string
        return totalCost.toFixed(2);
    };

    useEffect(() => {
        setGrandTotal(isGstApplicable === true ? parseFloat(sumCost(allItems) * 1.18)?.toFixed(2) : parseFloat(sumCost(allItems) * 1)?.toFixed(2))
        setGrandTotalGst(isGstApplicable === true ? parseFloat(sumCost(allItems) * 0.18)?.toFixed(2) : '00.00')
    }, [isGstApplicable, allItems])


    const getStoreGST = async () => {
        console.log("this is vendor identifier : ", invoiceDetails?.vendorIdentifier)
        const getStoreGSTResponse = await fetch(BASE_URL_API + "/getStoreGST",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    vendorIdentifier: invoiceDetails?.vendorIdentifier
                })
            });

        if (!getStoreGSTResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const getStoreGSTRespo = await getStoreGSTResponse.json()
            if (getStoreGSTRespo?.status === "success") {
                setAllGstRecords(getStoreGSTRespo?.response)
            } else {
                if (getStoreGSTRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {
                    setAllGstRecords([])
                    console.log(getStoreGSTRespo)
                }
            }
        }

    }

    const addStoreGST = async () => {
        setAddingGst(true)
        const addStoreGSTResponse = await fetch(BASE_URL_API + "/addStoreGST",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx?.token,
                    vendorIdentifier: invoiceDetails?.vendorIdentifier,
                    storeIdentifier: invoiceDetails?.storeIdentifier,
                    gstNumber: clientGst,
                    executiveIdentifier: authCtx?.userIdentifier,
                    tradeName: tradeName,
                    legalName: legalName,
                    businessAddress: businessAddress
                })
            });

        if (!addStoreGSTResponse.ok) {
            console.log(`Something went wrong : Server Error`)
        } else {
            const addStoreGSTRespo = await addStoreGSTResponse.json()
            if (addStoreGSTRespo?.status === "success") {
                toast(`GST added sucessfully!`)
                setClientGst('')
                getStoreGST()
            } else {
                if (addStoreGSTRespo?.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
                console.log(addStoreGSTRespo)
            }
        }
        setAddingGst(false)
    }

    useEffect(() => {
        if (isGstApplicable === true) {
            getStoreGST()
        }
    }, [isGstApplicable, invoiceDetails?.vendorIdentifier])

    const updateInvoicePaymentStatus = async (k) => {
        const updateInvoicePaymentStatusResponse = await fetch(BASE_URL_API + "/updateInvoicePaymentStatus",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    paymentStatus: k,
                    invoiceIdentifier: invoiceIdentifier
                })
            });

        if (!updateInvoicePaymentStatusResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateInvoicePaymentStatusRespo = await updateInvoicePaymentStatusResponse.json()
            if (updateInvoicePaymentStatusRespo.status === "success") {
                getInvoiceDetails()
                toast(`Successfully Updated payment status!`)
            } else {
                if (updateInvoicePaymentStatusRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateInvoicePaymentStatusRespo)
        }
    }

    const updateInvoiceParams = async () => {
        setIsUpdatingInvoice(true)
        const updateInvoiceParamsResponse = await fetch(BASE_URL_API + "/updateInvoiceParams",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    invoiceIdentifier: invoiceIdentifier,
                    totalAmount: grandTotal,
                    totalTax: grandTotalGst,
                    notes: tnc,
                    dueDate: dueDate
                })
            });

        if (!updateInvoiceParamsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateInvoiceParamsRespo = await updateInvoiceParamsResponse.json()
            if (updateInvoiceParamsRespo.status === "success") {
                setHasUpdated(true)
                toast(`Successfully updated the invoice!`)
            } else {
                if (updateInvoiceParamsRespo.message === "token Expired, please login again!") {
                    authCtx.logout()
                } else {

                }
                console.log(updateInvoiceParamsRespo)
            }
        }
        setIsUpdatingInvoice(false)
    }

    const handleDateChange = (e) => {
        setDueDate(e.target.value); // Set dueDate to the input value
        console.log("Due Date Changed:", e.target.value); // Log the input date
    };

    useEffect(() => {
        setHasUpdated(false)
    }, [isGstApplicable, allItems, tnc])


    useEffect(() => {
        setTnc([
            `Kindly pay the amount by ${dueDate}.`,
            `A ${replacementPeriod} replacement period is applicable for the Plus plan.`,
            `The replacement period ends on ${addPeriodToDate(dueDate, replacementPeriod?.split(" ")[1], replacementPeriod?.split(" ")[0])?.toString()?.substring(0, 15)}.`
        ])
    }, [dueDate, replacementPeriod])


    return (
        <div className={styles.mainWrapper}>
            <ToastContainer />
            {isSelectVendorOpen && <SelectVendor isOpen={isSelectVendorOpen} onClose={toggleSelectVendor} onSelect={selectVendorHandler} />}
            {isAddingItemOpen && <AddItemToInvoicePopUp isOpen={isAddingItemOpen} onSuccess={onSuccessItemAdding} onClose={toggleAddingItem} vendorIdentifier={invoiceDetails?.vendorIdentifier} invoiceIdentifier={invoiceIdentifier} />}
            <div className={styles.mainContainer}>
                <div className={styles.navWrapper}>

                </div>
                <div className={styles.editingWrapper}>
                    <div className={styles.fieldWrapper}>
                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachFieldTitle}>
                                Invoice No.
                            </div>
                            <div className={styles.eachFieldInput}>
                                <input value={invoiceDetails?.invoiceNumber} readOnly />
                            </div>
                        </div>

                        <div className={styles.eachFieldWrapper}>
                            <div className={styles.eachFieldTitle}>
                                Please select account
                            </div>
                            <div className={styles.eachFieldInput}>
                                <div onClick={() => setIsSelectVendorOpen(true)} className={styles.selectedVendor}>
                                    {invoiceDetails?.customerName || 'Click to select a salon'}
                                    <div className={styles.address}>
                                        {invoiceDetails?.customerAddress || ''}
                                    </div>
                                </div>
                            </div>
                        </div>


                        {invoiceDetails?.vendorIdentifier !== '' &&
                            <div className={styles.itemsWrapper}>
                                <div className={styles.itemsContainer}>
                                    <div>
                                        {allItems?.map((item, index) => (
                                            <div key={index} className={styles.eachItem}>
                                                <div className={styles?.itemName}>
                                                    {item?.itemName}
                                                </div>
                                                <div className={styles?.quantity}>
                                                    x {item?.quantity}
                                                </div>
                                                <div className={styles?.unitPrice}>
                                                    {item?.unitPrice}
                                                </div>
                                                <div className={styles?.taxPercentage}>
                                                    {item?.taxPercentage}
                                                </div>
                                                <div>
                                                    <button onClick={() => removeItemFromInvoice(item?.invoiceItemIdentifier)} >Remove</button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div onClick={() => setIsAddingItemOpen(true)}
                                        className={styles.addNewItemWrapper}
                                    >
                                        Add Item +
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={styles.gstWrapper}>
                            <div className={styles.isGstApplicable}
                                onClick={() => setIsGstApplicable(!isGstApplicable)}
                            >
                                GST
                                {isGstApplicable === true ? <FaToggleOn size={26} /> : <FaToggleOff size={26} />}
                            </div>
                            {isGstApplicable === true &&
                                <div className={styles.gstTypeWrapper}>
                                    <div onClick={() => setGstType('IGST')} className={styles.eachGstType} >
                                        {gstType === 'IGST' ?
                                            <MdOutlineRadioButtonChecked />
                                            :
                                            <MdOutlineRadioButtonUnchecked />
                                        }
                                        <div className={styles.igst}>
                                            IGST
                                        </div>
                                    </div>
                                    <div onClick={() => setGstType('LOCAL')} className={styles.eachGstType}>
                                        {gstType === 'LOCAL' ?
                                            <MdOutlineRadioButtonChecked />
                                            :
                                            <MdOutlineRadioButtonUnchecked />
                                        }
                                        <div className={styles.local}>
                                            CGST & SGST
                                        </div>
                                    </div>
                                </div>
                            }
                            {isGstApplicable === true &&
                                <div className={styles.clientGstWrapper}>
                                    <div className={styles.clientGstTitle}>
                                        Client GST Details
                                    </div>
                                    <div>
                                        {allGstRecords?.map((item, index) => (
                                            <div key={index} className={styles.eachGst} onClick={() => setSelectedGst(item?.gstNumber === selectedGst?.gstNumber ? null : item)} >
                                                <div>
                                                    {selectedGst?.gstNumber === item?.gstNumber ?
                                                        <MdOutlineRadioButtonChecked />
                                                        :
                                                        <MdOutlineRadioButtonUnchecked />
                                                    }
                                                    &nbsp;
                                                </div>
                                                <div>
                                                    <div>
                                                        {item?.gstNumber}
                                                    </div>
                                                    <div>
                                                        {item?.legalName}
                                                    </div>
                                                    <div className={styles.businessAddress}>
                                                        {item?.businessAddress}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}

                                    </div>
                                    <div className={styles.gstNumInput}>
                                        <input placeholder="Enter new GST number." onChange={(e) => setClientGst(e.target.value)} />
                                        <input placeholder="Enter Legal Name" value={legalName} onChange={(e) => setLegalName(e.target.value)} />
                                        <input placeholder="Enter Trade Name" value={tradeName} onChange={(e) => setTradeName(e.target.value)} />
                                        <textarea placeholder="Enter business address..." defaultValue={businessAddress} onChange={(e) => setBusinessAddress(e.target.value)}  >
                                        </textarea>
                                        {addingGst === true ? <button >Adding GST...</button> : <button onClick={() => addStoreGST()} >Add GST</button>}
                                    </div>
                                </div>
                            }
                        </div>

                        <div className={styles.gstWrapper}>
                            <div>
                                <div>Payment Status</div>
                                <div>
                                    <div className={styles.paymentStatus} >
                                        <button onClick={() => updateInvoicePaymentStatus(`Pending`)} className={invoiceDetails?.paymentStatus === `Pending` ? styles.eachPayStatusActive : styles.eachPayStatus}>Pending</button>
                                        <button onClick={() => updateInvoicePaymentStatus(`Partially_Paid`)} className={invoiceDetails?.paymentStatus === `Partially_Paid` ? styles.eachPayStatusActive : styles.eachPayStatus}>Partially Paid</button>
                                        <button onClick={() => updateInvoicePaymentStatus(`Paid`)} className={invoiceDetails?.paymentStatus === `Paid` ? styles.eachPayStatusActive : styles.eachPayStatus}>Paid</button>
                                        <button onClick={() => updateInvoicePaymentStatus(`Overdue`)} className={invoiceDetails?.paymentStatus === `Overdue` ? styles.eachPayStatusActive : styles.eachPayStatus}>Overdue</button>
                                        <button onClick={() => updateInvoicePaymentStatus(`Write Off`)} className={invoiceDetails?.paymentStatus === `Write Off` ? styles.eachPayStatusActive : styles.eachPayStatus}>Write Off</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className={styles.gstWrapper}>
                            <div>
                                Joining Date : Due Date
                                <input type="date"
                                    value={dueDate}
                                    onChange={(e) => handleDateChange(e)}
                                />
                            </div>
                            <div>
                                <div>
                                    Replacement Period
                                </div>
                                <div>
                                    <select onChange={(e) => setReplacementPeriod(e.target.value)}
                                        defaultValue={replacementPeriod}
                                    >
                                        <option value={null}>No Replacement</option>
                                        <option value="7 days" >7 Days</option>
                                        <option value="21 days" >21 Days</option>
                                        <option value="28 days" >28 Days</option>
                                        <option value="30 days" >30 Days</option>
                                        <option value="45 days" >45 Days</option>
                                        <option value="60 days" >60 Days</option>
                                        <option value="90 days" >90 Days</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div className={styles.previewWrapper}>
                    <div className={styles.pwBodyWrapper}
                        id="content-id"
                    >
                        <div className={styles.mainHeader}>
                            <div className={styles.mhLeft}>
                                <div className={styles.mhlFirmName}>Lokaci Innovations & Technologies Private Limited</div>
                                <div className={styles.mhlFirmAddress}>GT-08, Sector - 117, Noida, Baraula, Gautam Buddha Nagar, Dadri, Uttar Pradesh, India, 201304</div>
                            </div>
                            <div>
                                <LogoInvoice />
                            </div>
                        </div>

                        <div className={styles.secondSectionWrapper}>
                            <div className={styles.ssColLeft}>
                                <div className={styles.upperRow}>
                                    GSTIN : 09AAFCL5196A1ZH
                                </div>
                                <div className={styles.rowTwoCdTitle}>
                                    Customer Details
                                </div>
                                {selectedGst !== null ?
                                    <div className={styles.customerDetailsWrapper}>
                                        <div className={styles.cdEachRow}>
                                            <div className={styles.cdTitle}>M/S</div>
                                            <div className={styles.cdValClientName} >{selectedGst?.tradeName}</div>
                                        </div>
                                        <div className={styles.cdEachRow}>
                                            <div className={styles.cdTitle}>Address</div>
                                            <div className={styles.cdValaddress} >{selectedGst?.businessAddress}</div>
                                        </div>
                                        <div className={styles.cdEachRow}>
                                            <div className={styles.cdTitle}>Phone</div>
                                            <div className={styles.cdVal} >{invoiceDetails?.customerPhone}</div>
                                        </div>
                                        <div className={styles.cdEachRow}>
                                            <div className={styles.cdTitle}>GST</div>
                                            <div className={styles.cdVal} >{selectedGst?.gstNumber}</div>
                                        </div>
                                    </div>
                                    :
                                    <div className={styles.customerDetailsWrapper}>
                                        <div className={styles.cdEachRow}>
                                            <div className={styles.cdTitle}>M/S</div>
                                            <div className={styles.cdValClientName} >{invoiceDetails?.customerName}</div>
                                        </div>
                                        <div className={styles.cdEachRow}>
                                            <div className={styles.cdTitle}>Address</div>
                                            <div className={styles.cdValaddress} >{invoiceDetails?.customerAddress}</div>
                                        </div>
                                        <div className={styles.cdEachRow}>
                                            <div className={styles.cdTitle}>Phone</div>
                                            <div className={styles.cdVal} >{invoiceDetails?.customerPhone}</div>
                                        </div>
                                        <div className={styles.cdEachRow}>
                                            <div className={styles.cdTitle}>GST</div>
                                            <div className={styles.cdVal} >{selectedGst}</div>
                                        </div>
                                    </div>

                                }


                            </div>
                            <div className={styles.ssColRight}>
                                <div className={styles.upperRowOrg}>
                                    ORIGINAL FOR RECEIPIENT
                                </div>
                                <div className={styles.rowTwo}>

                                </div>
                                <div className={styles.invoiceDataWrapper}>
                                    <div className={styles.idwEachRow}>
                                        <div className={styles.eachofrWrapper}>
                                            <div className={styles.invoiceTitle}>Invoice No.</div>
                                            <div className={styles.invoiceValue} >{invoiceDetails?.invoiceNumber}</div>
                                        </div>
                                        <div className={styles.eachofrWrapper}>
                                            <div className={styles.invoiceTitle}>Invoice Date</div>
                                            <div className={styles.invoiceValue} >{invoiceDetails?.createdOn}</div>
                                        </div>
                                        <div className={styles.eachofrWrapper}>
                                            <div className={styles.invoiceTitle}>Due Date</div>
                                            <div className={styles.invoiceValue} >{dueDate}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className={styles.itemsTable}>
                            <div className={styles.itUpperRow}>
                                <div className={styles.sn}>S. No.</div>
                                <div className={styles.productName}>Name of Product / Service</div>
                                <div className={styles.gst}>GST</div>
                                <div className={styles.hsn}>HSN/SAC</div>
                                <div className={styles.qty}>Qty.</div>
                                <div className={styles.rate}>Rate</div>
                                <div className={styles.taxableValue}>Taxable Value</div>
                            </div>
                            <div className={styles.itemsRowWrapper}>
                                {allItems?.map((item, index) => (
                                    <div className={styles.eachItemRow} key={index} >
                                        <div className={styles.sn}>{index + 1}.</div>
                                        <div className={styles.productName}>{item?.itemName}</div>
                                        <div className={styles.gst}>GST</div>
                                        <div className={styles.hsn}>REC-{parseInt(item?.unitPrice * 1.8)}</div>
                                        <div className={styles.qty}>{item?.quantity}</div>
                                        <div className={styles.rate}>₹{item?.unitPrice}</div>
                                        <div className={styles.taxableValue}>₹{item?.unitPrice}</div>
                                    </div>
                                ))}

                                <div className={styles.eachItemRow} >
                                    <div className={styles.sn}></div>
                                    <div className={styles.productName}></div>
                                    <div className={styles.gst}></div>
                                    <div className={styles.hsn}></div>
                                    <div className={styles.qty}></div>
                                    <div className={styles.rate}></div>
                                    <div className={styles.taxableValue}>{sumCost(allItems)}</div>
                                </div>

                                {isGstApplicable === true ?
                                    <div className={styles.eachItemRow} >
                                        <div className={styles.sn}></div>
                                        <div className={styles.productName}></div>
                                        <div className={styles.gst}>{gstType === "IGST" ? `IGST(18%)` : `IGST(0%)`}</div>
                                        <div className={styles.hsn}></div>
                                        <div className={styles.qty}>{ }</div>
                                        <div className={styles.rate}>{ }</div>
                                        <div className={styles.taxableValue}>{gstType === "IGST" ? parseFloat(sumCost(allItems) * 0.18)?.toFixed(2) : `00.00`}</div>
                                    </div>
                                    :
                                    <div className={styles.eachItemRow} >
                                        <div className={styles.sn}></div>
                                        <div className={styles.productName}></div>
                                        <div className={styles.gst}>{`IGST(0%)`}</div>
                                        <div className={styles.hsn}></div>
                                        <div className={styles.qty}>{ }</div>
                                        <div className={styles.rate}>{ }</div>
                                        <div className={styles.taxableValue}>{`00.00`}</div>
                                    </div>
                                }
                                <div className={styles.eachItemRow} >
                                    <div className={styles.sn}></div>
                                    <div className={styles.productName}></div>
                                    {isGstApplicable === true ?
                                        <div className={styles.gst}>{gstType === "LOCAL" ? `CGST(9%)` : `CGST(0%)`}</div>
                                        :
                                        <div className={styles.gst}>{`CGST(0%)`}</div>
                                    }
                                    <div className={styles.hsn}></div>
                                    <div className={styles.qty}>{ }</div>
                                    <div className={styles.rate}>{ }</div>
                                    {isGstApplicable === true ?
                                        <div className={styles.taxableValue}>{gstType === "LOCAL" ? parseFloat(sumCost(allItems) * 0.09)?.toFixed(2) : `00.00`}</div>
                                        :
                                        <div className={styles.taxableValue}>{`00.00`}</div>
                                    }
                                </div>
                                <div className={styles.eachItemRow} >
                                    <div className={styles.sn}></div>
                                    <div className={styles.productName}></div>
                                    {isGstApplicable === true ?
                                        <div className={styles.gst}>{gstType === "LOCAL" ? `SGST(9%)` : `SGST(0%)`}</div>
                                        :
                                        <div className={styles.gst}>{`SGST(0%)`}</div>
                                    }
                                    <div className={styles.hsn}></div>
                                    <div className={styles.qty}>{ }</div>
                                    <div className={styles.rate}>{ }</div>
                                    {isGstApplicable === true ?
                                        <div className={styles.taxableValue}>{gstType === "LOCAL" ? parseFloat(sumCost(allItems) * 0.09)?.toFixed(2) : `00.00`}</div>
                                        :
                                        <div className={styles.taxableValue}>{`00.00`}</div>
                                    }
                                </div>
                                <div className={styles.emptyRow} >
                                    <div className={styles.sn}></div>
                                    <div className={styles.productName}></div>
                                    <div className={styles.gst}></div>
                                    <div className={styles.hsn}></div>
                                    <div className={styles.qty}>{ }</div>
                                    <div className={styles.rate}>{ }</div>
                                    <div className={styles.taxableValue}>{ }</div>
                                </div>
                                <div className={styles.emptyRow} >
                                    <div className={styles.sn}></div>
                                    <div className={styles.productName}></div>
                                    <div className={styles.gst}></div>
                                    <div className={styles.hsn}></div>
                                    <div className={styles.qty}>{ }</div>
                                    <div className={styles.rate}>{ }</div>
                                    <div className={styles.taxableValue}>{ }</div>
                                </div>
                                <div className={styles.totalRow} >
                                    <div className={styles.sn}></div>
                                    <div className={styles.productName}>Total</div>
                                    <div className={styles.gst}></div>
                                    <div className={styles.hsn}></div>
                                    <div className={styles.qty}>{sumQuantities(allItems)}</div>
                                    <div className={styles.rate}>{ }</div>
                                    <div className={styles.taxableValue}>₹{grandTotal}</div>
                                </div>
                            </div>

                        </div>

                        <div className={styles.stampWrapper}>
                            {invoiceDetails?.paymentStatus === 'Paid' ?
                                <PaidStamp />
                                :
                                <UnpaidStamp />
                            }
                        </div>

                        <div className={styles.inwordsWrapper}>
                            <div className={styles.totalInWordsTitle}>
                                Total In Words:
                            </div>
                            <div className={styles.totalInWords}>
                                {numberToWords(parseFloat(grandTotal).toFixed(0))} Only
                            </div>
                            <div className={styles.totalTaxInWords}>
                                Total Tax In Words : {numberToWords(parseFloat(grandTotalGst).toFixed(0))} Only
                            </div>
                        </div>

                        <div className={styles.bankDetailsWrapper}>
                            <div className={styles.bdTitle}>
                                Bank Details
                            </div>
                            <div className={styles.bdeRow}>
                                <div className={styles.bdeSmall}>
                                    Name
                                </div>
                                <div className={styles.bdeBig}>
                                    LOKACI INNOVATIONS AND TECHNO PVT LTD
                                </div>
                                <div className={styles.bdeSmall}>
                                    Branch
                                </div>
                                <div className={styles.bdeBig}>
                                    NOIDA SECTOR 1
                                </div>
                            </div>
                            <div className={styles.bdeRow}>
                                <div className={styles.bdeSmall}>
                                    Acc. Number
                                </div>
                                <div className={styles.bdeBig}>
                                    99919999648406
                                </div>
                                <div className={styles.bdeSmall}>
                                    IFSC
                                </div>
                                <div className={styles.bdeBig}>
                                    HDFC0001897
                                </div>
                            </div>
                        </div>


                        <div className={styles.tncWrapper}>
                            <div className={styles.tncTitle}>Terms & Conditions</div>
                            <div className={styles.tnc}>
                                <div>
                                    <ul>
                                        {tnc?.map((item, index) => (
                                            <li key={index}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={styles.pwActionWrapper}>
                        {hasUpdated === false ?
                            isUpdatingInvoice === true ?
                                <button className={styles.downloadPdfBtn}
                                    title="Please wait, updating..."
                                >
                                    <MdOutlineDownloading size={32} />
                                </button>
                                :
                                <button onClick={() => updateInvoiceParams()} className={styles.downloadPdfBtn}
                                    title="Update all details"
                                >
                                    <FaBookmark size={32} />
                                </button>
                            :
                            <button onClick={() => downloadPdfHandler()} className={styles.downloadPdfBtn} >
                                <IoMdCloudDownload size={32} />
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachHiringInvoice