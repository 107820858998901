import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import CreateNewInvoicePopUp from '../../Components/Invoices/CreateNewInvoicePopUp'
import MultiSelectDropdown from '../../Components/UI/MultiSelectDropdown'
import { executivesData, getExecutiveFullName } from '../../Helper/executivesData'
import { BASE_URL_API } from '../../References/urls'
import AuthContext from '../../store/auth-context'
import styles from './AllPartnersInvoices.module.css'
import { BiCalendar } from 'react-icons/bi';
import DateRangeSelector from '../../Components/UI/DateRangeSelector'

const AllPartnersInvoices = () => {
    const authCtx = useContext(AuthContext)
    const [createNewInvoiceOpen, setCreateNewInvoiceOpen] = useState(false)
    const [allInvoices, setAllInvoices] = useState([])
    const [filteredInvoices, setFilteredInvoices] = useState([])
    const [appliedPaymentStatusFilter, setAppliedPaymentStatusFilter] = useState([])
    const [appliedCreatedByFilter, setAppliedCreatedByFilter] = useState([])
    const [sortByColumn, setSortByColumn] = useState('')
    const [sortByColumnOrder, setSortByColumnOrder] = useState('asc')

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }

    const getAllPartnersInvoice = async () => {
        const getAllPartnersInvoiceResponse = await fetch(BASE_URL_API + "/getAllPartnersInvoice",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    startDate : dates?.startDate,
                    endDate : dates?.endDate
                })
            });

        if (!getAllPartnersInvoiceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllPartnersInvoiceRespo = await getAllPartnersInvoiceResponse.json()
            if (getAllPartnersInvoiceRespo?.status === "success") {
                setAllInvoices(getAllPartnersInvoiceRespo?.response)
                setFilteredInvoices(getAllPartnersInvoiceRespo?.response)
            } else {
                if (getAllPartnersInvoiceRespo.message === 'token Expired, please login again!') {
                    authCtx.logout()
                } else {
                    setAllInvoices([])
                    setFilteredInvoices([])
                }
                console.log(getAllPartnersInvoiceRespo)
            }
        }
    }

    useEffect(() => {
        getAllPartnersInvoice()
    }, [])

    useEffect(() => {
        getAllPartnersInvoice()
    }, [dates])


    const sumQuantities = (items) => {
        return items?.reduce((sum, item) => sum + parseInt(item?.totalAmount, 10), 0);
    }

    const sumPaidQuantities = (items) => {
        return items?.reduce((sum, item) => {
            if (item?.paymentStatus === "Paid") {
                return sum + parseInt(item?.totalAmount, 10);
            }
            return sum;
        }, 0);
    };

    const sumWriteOffAmount = (items) => {
        return items?.reduce((sum, item) => {
            if (item?.paymentStatus === "Write Off") {
                return sum + parseInt(item?.totalAmount, 10);
            }
            return sum;
        }, 0);
    };

    const sumUnpaidAmount = (items) => {
        return items?.reduce((sum, item) => {
            if (item?.paymentStatus === "Pending") {
                return sum + parseInt(item?.totalAmount, 10);
            }
            return sum;
        }, 0);
    };

    const sumOverdueAmount = (items) => {
        return items?.reduce((sum, item) => {
            if (item?.paymentStatus === "Overdue") {
                return sum + parseInt(item?.totalAmount, 10);
            }
            return sum;
        }, 0);
    };


    const getPaymentStatusClass = (paymentStatus) => {
        switch (paymentStatus.toLowerCase()) {
            case 'paid':
                return styles.paid;
            case 'unpaid':
                return styles.unpaid;
            case 'pending':
                return styles.pending;
            case 'overdue':
                return styles.overdue;
            default:
                return styles.writeOff;
        }
    }


    function filterAndSortInvoices(data, sortColumn, sortOrder = 'asc', filters = {}, searchKey = '') {
        // Destructure filters
        const { createdBy = [], paymentStatus = [] } = filters;

        // Filter based on createdBy and paymentStatus if provided
        let filteredData = data.filter(invoice => {
            let matchCreatedBy = createdBy.length ? createdBy.includes(invoice.createdBy) : true;
            let matchPaymentStatus = paymentStatus.length ? paymentStatus.includes(invoice.paymentStatus) : true;

            // General search across all fields
            let matchSearch = searchKey
                ? Object.values(invoice).some(value =>
                    typeof value === 'string' && value.toLowerCase().includes(searchKey.toLowerCase())
                )
                : true;

            return matchCreatedBy && matchPaymentStatus && matchSearch;
        });

        // Sort based on the provided column and order
        if (sortColumn) {
            filteredData.sort((a, b) => {
                let aValue = a[sortColumn] ? a[sortColumn].toString().toLowerCase() : '';
                let bValue = b[sortColumn] ? b[sortColumn].toString().toLowerCase() : '';

                if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1;
                if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        // return filteredData;
        setFilteredInvoices(filteredData)
    }

    useEffect(() => {
        const filters = {
            createdBy: appliedCreatedByFilter?.map(item => item?.userID), // Multiple values can be passed
            paymentStatus: appliedPaymentStatusFilter // Multiple values can be passed
        };
        filterAndSortInvoices(allInvoices, sortByColumn, sortByColumnOrder, filters, '')
    }, [appliedPaymentStatusFilter, sortByColumn, sortByColumnOrder, appliedCreatedByFilter])

    const setSortByColumnHandler = (k) => {
        if (sortByColumn === k) {
            setSortByColumnOrder(sortByColumnOrder === 'asc' ? 'desc' : 'asc')
        } else {
            setSortByColumn(k)
        }
    }

    const appliedPaymentStatusFilterHandler = (k) => {
        if (appliedPaymentStatusFilter?.includes(k)) {
            setAppliedPaymentStatusFilter(appliedPaymentStatusFilter?.filter(item => item !== k))
        } else {
            setAppliedPaymentStatusFilter([...appliedPaymentStatusFilter, k])
        }
    }

    const appliedCreatedByFilterHandler = (k) => {
        if (appliedCreatedByFilter?.includes(k)) {
            setAppliedCreatedByFilter(appliedCreatedByFilter?.filter(item => item !== k))
        } else {
            setAppliedCreatedByFilter([...appliedCreatedByFilter, k])
        }
    }

    const handleSelection = (selectedOptions) => {
        setAppliedCreatedByFilter(selectedOptions);
    };



    return (
        <div className={styles.mainWrapper}>
            {createNewInvoiceOpen && <CreateNewInvoicePopUp isOpen={createNewInvoiceOpen} onClose={() => setCreateNewInvoiceOpen(false)} />}
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.topRow}>
                        <div className={styles.dneTitle}>All Sales Invoices</div>
                        <div className={styles.dneWrapper}>
                            <div className={styles.dateWrapper}>
                                <div className={styles.dateRangeSelectorWrapperBtn}
                                    onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                                >
                                    {dates.startDate === dates.endDate ?
                                        <div>
                                            {dates.startDate} &nbsp;
                                        </div>
                                        :
                                        <div>
                                            From {dates.startDate} to {dates.endDate} &nbsp;
                                        </div>
                                    }

                                    <BiCalendar />
                                </div>
                                <DateRangeSelector
                                    isOpen={isDatePickerOpen}
                                    closeModal={closeModal}
                                    changeDateHandler={changeDateHandler}
                                />

                            </div>

                            <MultiSelectDropdown
                                options={executivesData}
                                onSelect={handleSelection} placeholder="Select Executive"
                                selectedOptions={appliedCreatedByFilter} // Pass the selected options as props
                            />

                            <div className={styles.createNew}>
                                <button onClick={() => setCreateNewInvoiceOpen(true)}>Create New +</button>
                            </div>
                        </div>
                    </div>
                    <div className={styles.secRow}>
                        <div>
                            <div>Revenue</div>
                            <div className={styles.revenueWrapper}>
                                <div className={styles.revenue}>₹{sumQuantities(allInvoices)}/-</div>
                                <div className={styles.revenueFiltered}>₹{sumQuantities(filteredInvoices)}/-</div>
                            </div>
                        </div>

                        <div className={styles.smallCardWrapper}>
                            <div className={appliedPaymentStatusFilter?.length === 0 ? styles.eachCardWrapperSelected : styles.eachCardWrapper} onClick={() => setAppliedPaymentStatusFilter([])}>
                                <div>Total Invoice</div>
                                <div>{filteredInvoices?.length} / {allInvoices?.length}</div>
                            </div>
                            <div className={appliedPaymentStatusFilter?.includes(`Paid`) ? styles.eachCardWrapperSelected : styles.eachCardWrapper} onClick={() => appliedPaymentStatusFilterHandler('Paid')}>
                                <div>Paid ({allInvoices?.filter((item) => item?.paymentStatus === "Paid")?.length})</div>
                                <div>
                                    ₹{sumPaidQuantities(allInvoices)}/-
                                </div>
                            </div>
                            <div className={appliedPaymentStatusFilter?.includes(`Overdue`) ? styles.eachCardWrapperSelected : styles.eachCardWrapper} onClick={() => appliedPaymentStatusFilterHandler('Overdue')}>
                                <div>Overdue ({allInvoices?.filter((item) => item?.paymentStatus === "Overdue")?.length})</div>
                                <div>
                                    ₹{sumOverdueAmount(allInvoices)}/-
                                </div>
                            </div>
                            <div className={appliedPaymentStatusFilter?.includes(`Pending`) ? styles.eachCardWrapperSelected : styles.eachCardWrapper} onClick={() => appliedPaymentStatusFilterHandler('Pending')}>
                                <div>Pending ({allInvoices?.filter((item) => item?.paymentStatus === "Pending")?.length})</div>
                                <div>
                                    ₹{sumUnpaidAmount(allInvoices)}/-
                                </div>
                            </div>
                            <div className={appliedPaymentStatusFilter?.includes(`Write Off`) ? styles.eachCardWrapperSelected : styles.eachCardWrapper} onClick={() => appliedPaymentStatusFilterHandler('Write Off')}>
                                <div>Write-Off ({allInvoices?.filter((item) => item?.paymentStatus === "Write Off")?.length})</div>
                                <div>
                                    ₹{sumWriteOffAmount(allInvoices)}/-
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.mainBody}>
                    <div className={styles.tableHeader}>
                        <div className={styles.invoice} onClick={() => setSortByColumnHandler('invoiceNumber')} >Invoice No.</div>
                        <div className={styles.customerName} >Vendor Name</div>
                        <div className={styles.amount} >Amount</div>
                        <div className={styles.gst} >GST</div>
                        <div className={styles.dueData} onClick={() => setSortByColumnHandler('dueDate')} >Due Data</div>
                        <div className={styles.currentStatus} onClick={() => setSortByColumnHandler('paymentStatus')} >Payment Status</div>
                        <div className={styles.currentStatus} onClick={() => setSortByColumnHandler('createdBy')} >Generated By</div>
                        <div className={styles.action} >Action</div>
                    </div>
                    <div className={styles.tableBodyWrapper}>
                        {filteredInvoices?.map((item, index) => (
                            <div className={`${styles.eachRow} ${getPaymentStatusClass(item?.paymentStatus)}`} key={index}>
                                <div className={styles.invoice} >{item?.invoiceNumber}</div>
                                <div className={styles.customerName} >
                                    <div className={styles.vendorName}>
                                        {item?.customerName}
                                    </div>
                                    <div className={styles.customerAddress}>
                                        {item?.customerAddress}
                                    </div>
                                </div>
                                <div className={styles.amount} >{item?.totalAmount}</div>
                                <div className={styles.gst} >{item?.totalTax}</div>
                                <div className={styles.dueData} >{item?.dueDate?.substring(0, 10)}</div>
                                <div className={styles.currentStatus} >{item?.paymentStatus}</div>
                                <div className={styles.currentStatus} >{getExecutiveFullName(item?.createdBy)}</div>
                                <div className={styles.action} >
                                    <Link to={`/hiring-invoice/${item?.invoiceIdentifier}`}>View</Link>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllPartnersInvoices